<template>
  <perseu-card :title="titleDialog">
    <template slot="content">
      <v-form ref="form" @keyup.enter.native="submitForm">
        <v-text-field
          v-model.trim="obj.name"
          label="Nome"
          :rules="[$rules.required]"
        />
        <select-lang v-model="obj.lang" :rules="[$rules.required]" />
      </v-form>
    </template>
    <template slot="actions">
      <v-btn color="secondary" @click="submitForm">Salvar</v-btn>
    </template>
  </perseu-card>
</template>

<script>
import { save } from "@/services/symptoms-service";
export default {
  props: {
    symptom: {
      type: Object,
    },
  },
  data: () => ({
    obj: {},
  }),
  computed: {
    titleDialog() {
      return (this.obj.id ? "Edição de" : "Novo") + " Sintoma";
    },
  },
  mounted() {
    this.obj = this.symptom || {};
  },
  methods: {
    async submitForm() {
      if (!this.$refs.form.validate()) return;
      try {
        this.$store.dispatch("loading/openDialog");

        let response = await save(this.obj);
        if (this.obj.id) {
          this.$emit("update", response.data);
        } else {
          this.$emit("create", response.data);
        }
        this.obj = {};
        this.$toasted.global.defaultSuccess();
      } catch (error) {
        this.$errorHandler(error);
      } finally {
        this.$store.dispatch("loading/closeDialog");
      }
    },
  },
};
</script>

<style></style>
